<template>
  <wrapper-base class="b-wrapper--login" :is-loading-prop="isLoading">
    <template slot="header">
      <div class="b-hide"></div>
    </template>

    <template slot="main">
      <login-area-layout />
    </template>
  </wrapper-base>
</template>

<script>
import { mapGetters } from 'vuex'
import WrapperBase from '~/components/_base/Wrapper'
import LoginAreaLayout from '~/components/_layouts/LoginArea'

export default {
  bodyClassData: 'freeze',
  components: {
    WrapperBase,
    LoginAreaLayout
  },
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading'
    })
  },
  head() {
    return this.currentSeoContent
  }
}
</script>
