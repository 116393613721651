<template>
  <div
    class="b-form__group"
    :class="[
      v.$error ? 'has-error' : '',
      (containerClassProp.length > 0) ? containerClassProp : '',
    ]"
  >
    <label
      v-if="labelProp.length > 0"
      class="b-form__label"
      :class="labelClassProp"
      :for="idProp"
    >{{ labelProp }}</label>

    <input
      :id="idProp"
      v-model.trim="inputValue"
      :type="typeProp"
      class="b-form__input"
      :class="classProp"
      :name="nameProp"
      :placeholder="placeholderProp"
      :min="minProp"
      :max="maxProp"
      :step="stepProp"
      :disabled="disabledProp === true ? disabledProp : null"
      :list="(datalistProp.length > 0 ) ? idProp + '-list': null"
      @blur="blurMethod"
      @focus="focusMethod"
      @keypress="keypressMethod"
    />
    <datalist
      :id="(datalistProp.length > 0 ) ? idProp + '-list': null"
    >
      <option
        v-for="(datalistOption,datalistKey) in datalistProp"
        :key="datalistKey"
        :value="datalistOption"
      ></option>
    </datalist>

    <div class="b-form__message">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    v: {
      type: Object,
      required: true
    },
    containerClassProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelProp: {
      type: String,
      default() {
        return ''
      }
    },
    labelClassProp: {
      type: String,
      default() {
        return null
      }
    },
    typeProp: {
      type: String,
      default() {
        return 'text'
      }
    },
    classProp: {
      type: String,
      default() {
        return null
      }
    },
    idProp: {
      type: String,
      default() {
        return null
      }
    },
    nameProp: {
      type: String,
      default() {
        return null
      }
    },
    placeholderProp: {
      type: String,
      default() {
        return null
      }
    },
    minProp: {
      type: Number,
      default() {
        return null
      }
    },
    maxProp: {
      type: Number,
      default() {
        return null
      }
    },
    stepProp: {
      type: Number,
      default() {
        return null
      }
    },
    disabledProp: {
      type: Boolean,
      default() {
        return false
      }
    },
    datalistProp: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    blurMethod() {
      this.$emit('input-blur-emit')
    },
    focusMethod() {
      this.$emit('input-focus-emit')
    },
    keypressMethod(e) {
      this.$emit('input-keypress-emit', e)
    }
  }
}
</script>
