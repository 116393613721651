<template>
  <div class="u-align-center l-login-area">
    <div v-if="isTestVersion" class="test-badge">TEST VERSION</div>
    <div class="l-login-area__container">

      <icon-base
        :icon-details="{
          name: 'AltitudeLogo',
          size: '',
          color: '',
          class: 'l-login-area__logo'
        }"
      />

      <icon-base
        :icon-details="{
          name: 'FullLogo',
          size: '',
          color: '',
          class: 'l-login-area__logo--secondary'
        }"
      />

      <div class="l-login-area__inner-container">
        <h1 class="b-typography-colour--text l-login-area__title">
          <span v-if="$nuxt.isOffline" class="b-typography-colour--1">Offline. Please check your connection</span>
          <span v-else>
            Welcome to Altitude
            <span class="b-typography-colour--1">Health &amp; Safety App</span>
          </span>
        </h1>

        <form
          class="b-form u-no-margin l-login-area__form"
          novalidate
          @submit.prevent="loginMethod"
        >
          <input-base
            v-model="formData.email"
            :disabled="isAuthenticating"
            :v="$v.formData.email"
            label-prop="Username"
            type-prop="email"
            id-prop="l-login-area__form__input--email"
            class-prop="b-form__input--border-white l-login-area__form__input l-login-area__form__input--email"
            container-class-prop="l-login-area__form__form-group"
            label-class-prop="l-login-area__form__label"
          >
            <template v-if="$v.formData.email.$dirty">
              <span v-if="!$v.formData.email.required">The email field is required</span>
              <span v-if="!$v.formData.email.email">Please input a valid email address</span>
              <span v-if="apiErrors.email !== null">{{ apiErrors.email }}</span>
            </template>
          </input-base>

          <input-base
            v-model="formData.password"
            :v="$v.formData.password"
            :disabled="isAuthenticating"
            label-prop="Password"
            type-prop="password"
            id-prop="l-login-area__form__input--password"
            class-prop="b-form__input--border-white l-login-area__form__input l-login-area__form__input--password"
            :container-class-prop="'l-login-area__form__form-group' + passwordIsIncorrectString"
            label-class-prop="l-login-area__form__label"
          >
            <template v-if="$v.formData.password.$dirty">
              <span v-if="!$v.formData.password.required">The password field is required</span>
            </template>

            <span v-if="apiErrors.password !== null">{{ apiErrors.password }}</span>

          </input-base>

          <div class="b-button-group b-form__group">
            <link-base
              class-prop="l-login-area__form__forget"
              href-prop="/forgotten-password"
              link-text="Forget your password?"
            />

            <button class="b-button b-button--one l-login-area__form__button" type="submit">{{ isAuthenticating ? "Logging in": "Login" }}</button>
          </div>
        </form>
      </div>

      <p class="u-no-margin l-login-area__registered">
        &reg;Havio {{ returnCurrentYear() }}.
        <link-base
          :use-normal-link="true"
          class-prop="l-login-area__registered-link"
          href-prop="https://www.havio.com/terms-conditions"
          link-text="Terms &amp; Conditions."
        />
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import IconBase from '~/components/_base/Icon'
import InputBase from '~/components/_base/form/Input'
import LinkBase from '~/components/_base/Link'

export default {
  components: {
    IconBase,
    InputBase,
    LinkBase
  },
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      apiErrors: {
        email: null,
        password: null,
      },
      validationPromise: null,
      isAuthenticating: false,
      passwordIsIncorrectString: ''
    }
  },
  validations: {
    formData: {
      email: {
        required,
        email,
        apiValidation()
        {
          return this.validateViaApiResponse("email")
        }
      },
      password: {
        required,
        apiValidation()
        {
          return this.validateViaApiResponse("password")
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      loginError: 'auth/getLoginError',
      userData: 'auth/getUserData'
    }),
    isTestVersion(){
      return ( process.env.isTestVersion === true || process.env.isTestVersion === 1)
    }
  },
  watch: {
    'formData.email'( newEmail ){

      this.validationPromise = null
      this.apiErrors.email = null
    },
    'formData.password'( newEmail ){

      this.validationPromise = null
      this.apiErrors.password = null
    }
  },
  methods: {
    loginMethod() {
      // validate
      this.$v.formData.$touch()

      // if its still pending or an error is returned do not submit
      if (this.$v.formData.$pending || this.$v.formData.$error) return

      this.isAuthenticating = true
      // to form submit after this
      this.validationPromise = this.$store.dispatch('auth/login', {
        email: this.formData.email,
        password: this.formData.password

      }).then((response)=> {
        // redirect from this page

        if (
                this.userData !== undefined &&
                Object.keys(this.userData).length > 0 &&
                this.userData.hasOwnProperty('after_login_redirect') &&
                this.userData.after_login_redirect !== undefined
        ) {
          const REDIRECT_LINK_CONST = this.$router.resolve({
            path: this.userData.after_login_redirect
          })

          if (
                  REDIRECT_LINK_CONST !== undefined &&
                  Object.keys(REDIRECT_LINK_CONST).length > 0 &&
                  REDIRECT_LINK_CONST.hasOwnProperty('href') &&
                  REDIRECT_LINK_CONST.href !== undefined
          ) {
            // redirect to the link if its valid
            this.$router.push({ path: REDIRECT_LINK_CONST.href })
          } else {
            // else redirect to inspections
            this.$router.push({ path: '/inspections' })
          }
        }
      }).finally(()=> {
        this.isAuthenticating = false
      })

    },
    validateViaApiResponse( fieldName ){

      if ( this.validationPromise ){

        return this.validationPromise
                .then(() => true)
                .catch(response => {

                  if (response.errors[fieldName] === undefined) {

                    return true
                  } else {
                    this.apiErrors[fieldName] = response.errors[fieldName].join()
                    return false
                  }
                })
                .finally(() => {
                  this.isBusy = false
                })

      }else{
        return true
      }
    },
  }
}
</script>

<style>
  .test-badge {
    background: #3c3c3b;
    color: #fff;
    display: block;
    font-weight: bold;
    padding: .55em 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
</style>